/* eslint-disable no-unused-vars */
// assets
import { IconWindmill, IconFileInvoice, IconTags, IconApps, IconBrandCodesandbox } from '@tabler/icons';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

// constant
const icons = {
    IconApps,
    IconBrandCodesandbox,
    IconWindmill,
    IconFileInvoice,
    IconTags,
    StorefrontOutlinedIcon,
    PlaceOutlinedIcon,
    LocalOfferOutlinedIcon,
    CategoryOutlinedIcon,
    PeopleOutlineIcon
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-products',
            title: 'Products',
            type: 'item',
            url: '/utils/products',
            icon: icons.IconApps,
            breadcrumbs: false
        },
        {
            id: 'util-orders',
            title: 'Orders',
            type: 'item',
            url: '/utils/orders',
            icon: icons.IconBrandCodesandbox,
            breadcrumbs: false
        },
        {
            id: 'util-invoices',
            title: 'Invoices',
            type: 'item',
            url: '/utils/invoices',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'util-offers',
            title: 'Offers',
            type: 'item',
            url: '/utils/offers',
            icon: icons.IconFileInvoice,
            breadcrumbs: false
        },
        {
            id: 'util-outlets',
            title: 'Outlets',
            type: 'item',
            url: '/utils/outlets',
            icon: icons.StorefrontOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'util-category',
            title: 'Category',
            type: 'item',
            url: '/utils/category',
            icon: icons.CategoryOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'util-brands',
            title: 'Brands',
            type: 'item',
            url: '/utils/brands',
            icon: icons.LocalOfferOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'util-area',
            title: 'Areas',
            type: 'item',
            url: '/utils/areas',
            icon: icons.PlaceOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'util-customers',
            title: 'Customers',
            type: 'item',
            url: '/utils/customers',
            icon: icons.PeopleOutlineIcon,
            breadcrumbs: false
        }
    ]
};

export default utilities;
